import React, {useState, useEffect} from "react";
import axios from "axios"
import canAutoPlay from 'can-autoplay';
import './App.css';

const PROD_HOST = "https://spellingdrill.com";
const DEV_HOST = "http://localhost:8180";
const API_HOST = PROD_HOST;

const AudioPlayer = (props) => {
  props.audio.addEventListener('ended', () => props.setPlaying(false));
  
  const toggle = () => {
    props.setPlaying(!props.playing);
  }

  useEffect(() => {
      props.playing ? props.audio.play() : props.audio.pause();
    },
    [props.playing]
  );

  return (
    <div className="audio-player centering">
      <button className={props.playing ? "pause" : "play"} onClick={toggle}/>
    </div>
  );
};

const Question = (props) => {
  return (
    <div className="explanation">
      <div className={props.revealed ? "" : "hidden"}>
        <h2 className="word">{props.exercise.word}</h2>
      </div>
      <div><b>Definition:</b> ({props.exercise.type})</div> 
      <div>{props.exercise.definition}</div>
      <div><b>Used in sentence:</b></div> 
      <div>{props.exercise.contextSentence}</div>
    </div>
  );
}

const Answer = (props) => {
  return (
    <div className="answer-input">
      <div className="row">
        <input type="text" id="answer" placeholder="Answer"
              value={props.answer} 
              onChange={(e) => props.setAnswer(e.target.value)}
              className={props.submitted ? (props.result ? "correct" : "incorrect") : "empty"}
              />
        
      </div>
      <div className="centering">
        <p id="result">{props.submitted ? (props.result ? "Good job!" : "Try again") : ""}</p>
        </div>
    </div>
  );
}

const Controlls = (props) => {

  function checkAnswer() {
    props.setResult(props.word.toLowerCase() === props.answer.toLowerCase());
    props.setSubmitted(true);
  }

  function reveal() {
    props.setRevealed(true);
  }

  return (
    <div className="controlls">
      <button className="simple-button" onClick={checkAnswer}>Check</button>
      <button className="simple-button" onClick={reveal}>Reveal</button>
      <button className="simple-button next" onClick={props.newQuestion}>Next</button>
    </div>
  );

}

const Info = () => {
  const [autoplay, setAutoplay] = useState(false);

  canAutoPlay.audio().then(({result}) => {
    setAutoplay(result);
  })

  return (<div className="info">
    <p>{autoplay ? "" : "Please enable autoplay in your browser settings for better experience"}</p>
  </div>);
}

const Exercise = () => {

  const [exercise, setExercise] = useState([]);
  const [answer, setAnswer] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [revealed, setRevealed] = useState(false);
  const [result, setResult] = useState("");

  const [audio, setAudio] = useState(new Audio());
  const [playing, setPlaying] = useState(false);

  const newQuestion = () => {
    console.log("New question!");
    fetchExercises();
    setAnswer("");
    setSubmitted(false);
    setRevealed(false);
    setResult("");
  }

  const fetchExercises = () => {
    axios.get(API_HOST+"/api/v1/exercise/one").then(res => {
      console.log(res);
      setExercise(res.data);
      console.log(res.data.word);
      setAudio(new Audio(API_HOST+"/api/v1/voicer/?word="+res.data.word));
    })
  };

  useEffect(() => {
    fetchExercises();
  },[]);

  useEffect(() => {
    audio.addEventListener('loadeddata', function() {
      if(exercise && audio.readyState >= 2) {
        setPlaying(true);
      }
    });
  }, [audio]);

  let audioProps = {
    audio: audio,
    playing: playing,
    setPlaying: setPlaying
  }

  let answerProps = {
    word: exercise.word,
    answer: answer,
    submitted: submitted,
    result: result,
    setAnswer: setAnswer,
    setResult: setResult
  }

  let controllsProps = {
    word: exercise.word,
    answer: answer,
    newQuestion: newQuestion,
    setSubmitted: setSubmitted,
    setRevealed: setRevealed,
    setResult: setResult
  }

  return (
    <div className="app">
      <div className="header centering"><h1>Spelling Drill</h1></div>
      <div className="main" >
        {/* <ImageCard word={exercise.word}/> */}
        <AudioPlayer {...audioProps}/>
        <Question exercise={exercise} revealed={revealed}/>
        <Answer {...answerProps}/>
        <Controlls {...controllsProps}/>
        <Info />
      </div>
    </div>
  );
    
}

function App() {
  return (
    <Exercise />
  );
}

export default App;
